import React from "react";
import Layout from "../components/layout"
import ContactFull from "../components/contact_full"
import HeroBanner from "../components/herobanner"
import heroimage from "../assets/images/services_hero@2x.jpg"
import ServicesCreate from "../copy/services_create";
import ServicesDesign from "../copy/services_design";
import ServicesDevelop from "../copy/services_develop";
import ServicesIntro from "../copy/services_intro";
import ServicesContentCopy from "../copy/services_content";
import ServicesHeroCopy from "../copy/services_hero";

export default function ServicesPage(props){
    return(
        <Layout className="borderless_mobile" pageTitle="Services">
            <HeroBanner title="services hero" heroimage={{publicURL: heroimage}}>
                <ServicesHeroCopy />
            </HeroBanner>
            
            <article id="services" className="solopage">
                <div className="content">
                    <ServicesIntro />
                </div>
                <div className="services_paged">
                    <div className="services_page">
                        <ServicesCreate />
                    </div>
                    
                    <div className="services_page">
                        <ServicesDesign />
                    </div>

                    <div className="services_page">
                        <ServicesDevelop />
                    </div>
                
                </div>

                <div className="content">
                    <ServicesContentCopy />
                </div>

                <div className="clearfix"></div>
            </article>   
            
            <ContactFull />
        </Layout> 
    )
}