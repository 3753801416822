import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

const ServicesDesignCopy = () => (
    <StaticQuery
      query={graphql`
        {
          allMdx(filter: {slug: {eq: "content/services_design"}}) {
            edges {
              node {
                id
                slug
                body
              }
            }
          }
        }
      `}
      render={data => (
          <MDXRenderer>{data.allMdx.edges[0].node.body}</MDXRenderer>
      )}
    ></StaticQuery>
  )

export default ServicesDesignCopy;